import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 1,
    lpSymbol: 'PANA-BNB LP',
    lpAddresses: {
      97: '0x52Cd4aDf3E3C54126d253596dDAB019EC9102901',
      56: '0xC1B8cC95fD4af1a1981622820016c2447A958281',
    },
    token: tokens.pana,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'PANA-BUSD LP',
    lpAddresses: {
      97: '0x718feE1ab951fa4C3657577393a28b968256aA33',
      56: '0xB0b5dd974e982c1E24E96A7936B6d25CfD81E26A',
    },
    token: tokens.pana,
    quoteToken: tokens.busd,
  },
  {
    pid: 4,
    lpSymbol: 'PANA-DOGE LP',
    lpAddresses: {
      97: '',
      56: '0x6A63B3d8df0CF32C7042a520636cB35AB71F21B5',
    },
    token: tokens.pana,
    quoteToken: tokens.doge,
  },
  {
    pid: 5,
    lpSymbol: 'PANA-ADA LP',
    lpAddresses: {
      97: '',
      56: '0xE6c154c99Dd315EA1797dEdD11Eae6e8cb073141',
    },
    token: tokens.pana,
    quoteToken: tokens.ada,
  },
  {
    pid: 6,
    lpSymbol: 'PANA-USDC LP',
    lpAddresses: {
      97: '',
      56: '0x3cd4736F96bE9Daa7C9C0fE5b82139c0ee0EDb49',
    },
    token: tokens.pana,
    quoteToken: tokens.usdc,
  },
  {
    pid: 7,
    lpSymbol: 'PANA-TWT LP',
    lpAddresses: {
      97: '',
      56: '0xD26A8ea0FFbCD107A0f0Ccd561472Fe78b2c191E',
    },
    token: tokens.pana,
    quoteToken: tokens.twt,
  },
  {
    pid: 3,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '0xF105C2bc319AD6940FE14387a949965Dc73d7CB3',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
  },
  {
    pid: 8,
    lpSymbol: 'DOGE-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xac109C8025F272414fd9e2faA805a583708A017f',
    },
    token: tokens.doge,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 9,
    lpSymbol: 'SUSHI-ETH LP',
    lpAddresses: {
      97: '',
      56: '0x16aFc4F2Ad82986bbE2a4525601F8199AB9c832D',
    },
    token: tokens.sushi,
    quoteToken: tokens.eth,
  },
  {
    pid: 10,
    lpSymbol: 'ADA-DOGE LP',
    lpAddresses: {
      97: '',
      56: '0x7888971ECE89caD06F34D1A0Bbb472f645543A03',
    },
    token: tokens.ada,
    quoteToken: tokens.doge,
  },
  
]

export default farms
