import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.pana,
    earningToken: tokens.pana,
    contractAddress: {
      97: '0xC436589BAA00b20bA0760aeF84F40Fd3743ADBCC',
      56: '0x51Ea19811Bc1f177C33f68844E2bc35E2Ad1Bb4d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 1,
    stakingToken: tokens.pana,
    earningToken: tokens.busd,
    contractAddress: {
      97: '0x22e4fAcf2cC560791669d987f49F493CFE27290C',
      56: '0x1fa13840b3084abcEEcEf36986ea467026946457',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00001',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 2,
    stakingToken: tokens.pana,
    earningToken: tokens.wbnb,
    contractAddress: {
      97: '0x5e5baaedf41722a3fb29fbf373a7c7473d3a4cf5',
      56: '0xd421420de4D97e800ab508743002c3496871EBf5',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000001',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 3,
    stakingToken: tokens.wbnb,
    earningToken: tokens.busd,
    contractAddress: {
      97: '0x147997f6a8B533811cDF9D868029fF41c57Dc5Ee',
      56: '0x722D5871333456447E1c6d82EF60EE7B7Cc91CA6',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000001',
    sortOrder: 1,
    isFinished: true,
  },
]

export default pools
