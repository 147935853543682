import BigNumber from 'bignumber.js'
import masterchefABI from 'config/abi/masterchef.json'
import erc20 from 'config/abi/erc20.json'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'
import { Farm, SerializedBigNumber } from '../types'

type PublicFarmData = {
  tokenAmountMc: SerializedBigNumber
  quoteTokenAmountMc: SerializedBigNumber
  tokenAmountTotal: SerializedBigNumber
  quoteTokenAmountTotal: SerializedBigNumber
  lpTotalInQuoteToken: SerializedBigNumber
  lpTotalSupply: SerializedBigNumber
  tokenPriceVsQuote: SerializedBigNumber
  poolWeight: SerializedBigNumber
  depositFee: SerializedBigNumber
  withdrawFee: SerializedBigNumber
  lockTime?: SerializedBigNumber
  tokenPerBlock: SerializedBigNumber
  multiplier: string
}

const fetchFarm = async (farm: Farm): Promise<PublicFarmData> => {
  const { pid, lpAddresses, token, quoteToken, isTokenOnly } = farm
  const lpAddress = getAddress(lpAddresses)
  const calls = [
    // Balance of token in the LP contract
    {
      address: getAddress(token.address),
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of quote token on LP contract
    {
      address: getAddress(quoteToken.address),
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of LP tokens in the master chef contract
    {
      address: lpAddress,
      name: 'balanceOf',
      params: [getMasterChefAddress()],
    },
    // Balance of Single token in the mc
    {
      address: getAddress(token.address),
      name: 'balanceOf',
      params: [getMasterChefAddress()],
    },
    // Total supply of LP tokens
    {
      address: lpAddress,
      name: 'totalSupply',
    },
    // Token decimals
    {
      address: getAddress(token.address),
      name: 'decimals',
    },
    // Quote token decimals
    {
      address: getAddress(quoteToken.address),
      name: 'decimals',
    },
  ]

  const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMC, singleTokenBalanceMC, lpTotalSupply, tokenDecimals, quoteTokenDecimals] =
  await multicall(erc20, calls)
  // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
    const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))
    
    // Raw amount of token in the LP, including those not staked
    const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
    const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))
    
    // Amount of token in the LP that are staked in the MC (i.e amount of token * lp ratio)
    const tokenAmountMc = tokenAmountTotal.times(lpTokenRatio)
    const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)
    
    // Total staked in LP, in quote token value
    const lpTotalInQuoteToken = isTokenOnly ? new BigNumber(singleTokenBalanceMC).div(BIG_TEN.pow(tokenDecimals)) : quoteTokenAmountMc.times(new BigNumber(2))
    
    // Only make masterchef calls if farm has pid
    const [info, totalAllocPoint, tokenPerBlock] =
    pid || pid === 0
    ? await multicall(masterchefABI, [
      {
        address: getMasterChefAddress(),
        name: 'poolInfo',
        params: [pid],
      },
      {
        address: getMasterChefAddress(),
        name: 'totalAllocPoint',
      },
      {
        address: getMasterChefAddress(),
        name: 'tokenPerBlock',
      },
    ])
    : [null, null, null]
    
    
    const allocPoint = info ? new BigNumber(info.allocPoint?._hex) : BIG_ZERO
    const poolWeight = totalAllocPoint ? allocPoint.div(new BigNumber(totalAllocPoint)) : BIG_ZERO
    const depositFee = info ? new BigNumber(info.depositFeeBP).div(100) : BIG_ZERO
    const withdrawFee = info ? new BigNumber(info.withdrawFeeBP).div(100) : BIG_ZERO
    const lockTime = info ? new BigNumber(info.lockTime?._hex) : BIG_ZERO
    
    return {
    tokenAmountMc: tokenAmountMc.toJSON(),
    quoteTokenAmountMc: quoteTokenAmountMc.toJSON(),
    tokenAmountTotal: tokenAmountTotal.toJSON(),
    quoteTokenAmountTotal: quoteTokenAmountTotal.toJSON(),
    lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
    lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
    tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
    poolWeight: poolWeight.toJSON(),
    depositFee: depositFee.toJSON(),
    withdrawFee: withdrawFee.toJSON(),
    lockTime: lockTime.toJSON(),
    tokenPerBlock: new BigNumber(tokenPerBlock).div(1e18).toJSON(),
    multiplier: `${allocPoint.div(100).toString()}X`,
  }
}

export default fetchFarm
