import { MenuEntry } from '@amazonswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Exchange'),
    icon: 'TradeIcon',
    href: '/swap',
  },
  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: t('Lottery'),
    icon: 'InfoIcon',
    href: '/lottery',
  },
  {
    label: t('Referral'),
    icon: 'PoolIcon',
    href: '/referral',
  },
]

export default config
